import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import TextArea from 'components/ui/text-area.jsx'
import Editor from 'react-simple-wysiwyg';

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
   message: Yup.string()
        .required('Question is required'),
});


let defaultValues = {  
	amount: '',
};

const AskQueAnswerForm = (props) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id, setUserId] = useState(localStorage.getItem('user_id'))

	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);
  const [html, setHtml] = useState('my <b>HTML</b>');

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
		
  	});


	

	
	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
		let inputN = {
			...input,
			parent_id:props?.question_id,
		}
     
      setLoading(true)
      axios.post(`${API_ENDPOINT}/question_answer_save`,inputN,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeWithdraw();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })

		
	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-2 dark:text-zinc-400">Answer For Question: {props?.question_txt} </p>
						<form onSubmit={handleSubmit(onSubmit)}>
								{/* <TextArea
		                     label='Your Answer'
		                     {...register('message')}                        
		                     variant="outline"
		                     className="mb-5 w-full"
		                     error={errors.message?.message}
		                     required
		                  />*/}
		                  <Controller
							    control={control}
							    name='message'
							    render={({ field }) => (
									<Editor
							      onChange={(date) => {field.onChange(date);
							      setHtml(date); }}
							      className="mb-5 w-full h-40"
							      value={field.value}
								    />
								  )}
  								/>
								<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto uppercase">Answer Now</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default AskQueAnswerForm