import React, {useState, useEffect, useRef} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, replaceWithBr, LIMIT, showRating} from 'components/config/Common'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import Seo from 'components/seo/seo'
import {Loading , LoadingLottie} from 'components/custom-icons'
import cn from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../common/NoRecords'



const ShowTestimonials = () => {
   const [loading, setLoading] = useState(true)
   const [details, setDetails] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)
   const [desc, setDesc] = useState(null)
   
   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getTestimonials(1)
   },[])


      useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/testimonials`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   
   const getTestimonials = (pg) => {
      fetch(`${API_ENDPOINT}/get_testimonials?page=${pg}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('testimonials=====',result)
         setDetails(result.data)
         setLoading(false)
         if(pg === 1){
            setDetails(result.data)
         }else{
            setDetails([...details, ...result.data])
         }
         let nextPage = page + 1
         setPage(nextPage)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         setTotalRec(result.total)
      })
   }

   const fetchData = () => {
      //console.log('page==',page)
      getTestimonials(page)
      
	}

   const showQuote = (text) => {
      return(
         <p>{text}</p>
      )
   }
   
   return(
      <ScrollPageTop >
         <Seo 
            title={`Testimonials - ${APP_NAME}`}
            desc="Testimonials ctmri"
            url={`${APP_URL}${Links.TESTIMONIALS}`}
            image={`./img/home-bg.jpg`}
         />
         <Header hidesearch="true"  />
         <div >{
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto bg-light w-full max-w-1920 py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20 ">
            {
            details &&
            <>
            <h1 className="mb-4 text-heading sm:mb-5  text-center">
            What Users Say About Us
            </h1>
            <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
            <InfiniteScroll
               dataLength={details.length} //This is important field to render the next data
               next={fetchData}
               hasMore={hasMore}
               loader={
                  <div className="h-20 mt-5 w-full md:max-w-[680px] mx-auto"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</div>
               }
               scrollThreshold={0.7}
            >
            <div class="w-full h-full md:columns-3 lg:columns-3 sm:columns-2 gap-6">
            {
            details && details.map((val, key) => (
            <>
                  <div className="flex group break-inside-avoid-column relative items-start justify-start mb-6 min-h-full bg-gray-100 rounded-2xl" style={{BorderRadous:'20%'}}>
                        <div className="border-gray-100 border-[1px] px-2 py-3 rounded-2xl  ">
                           <div className="flex flex-col px-2 py-2 pb-14  text-center">
                              <img src={`${IMG_URL}support/left-quote-svgrepo-com.png`} className="w-10 h-10" />
                              <div className="mt-3">{val.testimony}</div>
                              <div className="flex justify-center">{showRating(val.rating)}</div>
                           </div>
                           <div className="absolute bottom-0 left-0 bg-white p-2 rounded-tr-lg mt-3 text-accent flex justify-center items-center gap-2">
                              <div><img src={`https://ui-avatars.com/api/?name=${val.name}&background=53746C&color=FFFFFF`} className="w-12 rounded-full" /></div>
                              <div>{val.name}</div>
                           </div>
                        </div>
                     </div>
               
           </>
            ))
            }
            </div>
            </InfiniteScroll>
            </>
            }
         </section>
         }
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default ShowTestimonials