import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT ,amount_format,showRating , EXPERT_LABEL} from 'components/config/Common'
import NoRecords from 'pages/common/NoRecords'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import Badge from "components/ui/badge/badge";
import { Tab } from '@headlessui/react'

import UserUpcomingAppointment from "components/appointment/UserUpcomingAppointment";
import UserTodaysAppointment from "components/appointment/UserTodaysAppointment";
import UserPendingAppointment from "components/appointment/UserPendingAppointment";
const MyAppointmentsSchedule = () => {

	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id , setUser_id] = useState(localStorage.getItem('user_id'));
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	//const [transactions,setTransactions]= useState([]);
	const [doctorratings,setDoctorratings]= useState([]);

	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
    const [currentab ,setcurrentab] = useState(0);

	useEffect(() => {
		
		getDoctorratings(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My ${EXPERT_LABEL} Ratings - ${APP_NAME}`,
		description: `My ${EXPERT_LABEL} Ratings - ${APP_NAME}`,
	}

	const getDoctorratings = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/get_expert_ratings?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setDoctorratings(result.data)
         }else{
            setDoctorratings([...doctorratings, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         //setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
		getDoctorratings(page)
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400">My Appointment Schedules</p>
						<div className="w-full  px-2 py-2 sm:px-0">
							<Tab.Group onChange={(index) => {
						        console.log('Changed selected tab to:', index)
						        setcurrentab(index);
						      }}>
						      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1" >
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  ${currentab == 0 ? ' bg-white shadow ': '' }`}>Today's Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 1 ? ' bg-white shadow ': '' }`}>Upcoming Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 2 ? ' bg-white shadow ': '' }`}>Pending Appointment</Tab>
						      </Tab.List>
						      <Tab.Panels>
						        <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"><UserTodaysAppointment /></Tab.Panel>
						        <Tab.Panel><UserUpcomingAppointment /></Tab.Panel>
						        <Tab.Panel><UserPendingAppointment /></Tab.Panel>
						      </Tab.Panels>
						    </Tab.Group>
						</div>
					</div>
				</div>
			
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default MyAppointmentsSchedule