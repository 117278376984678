import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'
import { Disclosure } from '@headlessui/react'

import { AiOutlineArrowDown } from "react-icons/ai";
   
const Faq = (props) => {
   
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/faq`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/faq`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true" />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-8 xl:px-16 2xl:px-20">
            <h1 className="text-heading mt-2 ">
            {details?.title}
            </h1>
            
         </section>
      }
      <div className="w-full py-8 px-4 lg:px-16 pt-0 pb-10 bg-light">
        <Disclosure>
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">What does it offer?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel  transition className="px-4 pt-4 pb-2  text-gray-500">A unique peer support platform that offers you a safe space to express your feelings, connect with others, and access to expert guidance for mental health and self-improvement.</Disclosure.Panel>
           </>
         )}
       </Disclosure>
       <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">Who are experts?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">Experts consist of a diverse panel of experienced professionals, including therapists, counselling psychologists, life coaches, career coaches, relationship coaches, and sex educators. They have expertise in various areas addressing issues ranging from relationship problems, self-esteem, to anxiety and depression. They are here to provide you with the support you need on your mental health and self-improvement journey.</Disclosure.Panel>
           </>
         )}
       </Disclosure>
        <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">Can I remain anonymous while using it?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">
             Yes, you have the option to remain anonymous or use pseudonyms when sharing your thoughts and experiences on it. We understand the importance of providing a space where you can express yourself freely without the pressure of revealing your identity.
             </Disclosure.Panel>
           </>
         )}
       </Disclosure>
      
        <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">Can we get mental health support for children?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">
             Yes, you can get mental health support for children. Experts specializing in child psychology and mental well-being are available to provide guidance and assistance. You can find and book an appointment with a qualified expert through our platform.
             </Disclosure.Panel>
           </>
         )}
       </Disclosure>


       
       
        <Disclosure>
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">Can I schedule an appointment based on my availability?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">You can request an appointment based on your available time. However, the final schedule will depend on the expert's availability. Once approved, the expert will be available at the scheduled time.</Disclosure.Panel>
           </>
         )}
       </Disclosure>
       <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">How can I buy coins?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500">
             To purchase coins, go to the 'My Wallet' menu on the website and select 'Add Money to Wallet.' Enter the desired amount and click the 'Add' button to proceed. You will be redirected to the payment options. Once the payment is completed, the corresponding coins will be added to your account at a rate of 1 INR = 1 coin.
             </Disclosure.Panel>
           </>
         )}
       </Disclosure>
        <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">How can I find an expert who speaks my language?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">
On the 'Find an Expert' page, you can view all available experts. To find an expert who speaks your language, click the 'Filter' button. In the filter options, select the desired language, and the system will display experts who can communicate in that language.             </Disclosure.Panel>
           </>
         )}
       </Disclosure>

         <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.2] px-4 py-2 text-left text-xl  hover:bg-accent/[0.2] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className="uppercase">How do I find the right mental health expert for my needs?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-500">
         <h5>To find the right mental health expert for your needs, follow these steps:</h5>
         <ul className="list-decimal pl-6" >
         <li>
         Visit the ‘Find an Expert’ Page: Browse through the list of available mental health professionals.
         </li>
         <li>
         Use Filters to Narrow Your Search: You can filter experts based on specialization, language, experience, and availability.
         </li>
         <li>
         Read Expert Profiles: Check their qualifications, expertise, and reviews from other users.
         </li>
         <li>
         Consider Your Specific Needs: Choose an expert who specializes in the area you need help with, such as anxiety, depression, stress management, or child psychology. 
         </li>
         </ul>
</Disclosure.Panel> 
</>
         )}
       </Disclosure>
      
       
       </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default Faq