import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format ,BACKEND_URL , myPrettyDateFormat } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextArea from 'components/ui/text-area';

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	appointment: Yup.date()
        .required('Please choose date.')
});


let defaultValues = {  
	comment: '',
};

const ReqAppointmentFrm = (props) => {
	const {info} = props;
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
		
  	});

	const [startDate, setStartDate] = useState(new Date());

   let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
   };


	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true);		

		let input = values;
     	input = {...input,expert_id:info?.id , appointment:myPrettyDateFormat(values?.appointment)};
     	console.log("INPUT DATA ",input);
      setLoading(true)
      axios.post(`${API_ENDPOINT}/schedule_appointment`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeCalling();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })
     

		
	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Request Appointment</p>
						<div className="border-b-[1px] mb-2 pb-2">
						{info?.name ?
							(<>
								<div className="text-accent">
								<div className="flex w-full flex-wrap justify-start w-1/2">

                                   <div className="h-8 overflow-hidden shadow rounded-full w-8	 h-auto align-middle border-none flex-wrap flex">
                                      {info?.profile_image?
                                       <img src={`${BACKEND_URL}${info?.profile_image}`} className="object-cover w-full" alt={info?.name}  />
                                       :
                                       <img src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} alt="{info?.name}" className="shadow rounded-full max-w-full h-auto align-middle border-none" sizes="100vw" />
                                       }
                                   </div>
                                 </div>     
								Doctor - {info?.name}</div>
							 </>)
							:
							null
						}
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex w-full flex-col">
									<div className="mb-10 w-full">
									<Controller
								    control={control}
								    name='appointment'
								    render={({ field }) => (
										<DatePicker
								      showTimeSelect
								      selected={startDate}
								      onChange={(date) => {field.onChange(date);
								      setStartDate(date); }}
								      timeClassName={handleColor}
								      minDate={(new Date())- 1}
								      dateFormat="yyyy/MM/dd hh:mm a"
								    />
								     )}
  									/>
								    </div>
								    {/*<div className="mb-5 w-full">
										 <TextArea
				                   	name="comment"
				                   	placeholder="Message"
				                   	className="w-full  mb-2"
				                   	{...register('comment')}
				                    />
				                  </div>*/}
	                      </div>
								<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Submit</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default ReqAppointmentFrm