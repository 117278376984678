import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {BACKEND_URL , APP_URL, APP_NAME , IMG_URL , LIMIT , amount_format ,showRating} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import SessionReviewFrm from "components/reviews/SessionReviewFrm";
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

const MyCallSession = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
   const [sessioninfo,setSessioninfo] = useState(null);
	useEffect(() => {
		getcallsessionLog(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My Call Session - ${APP_NAME}`,
		description: `My Call Session - ${APP_NAME}`,
	}


	const getcallsessionLog = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/get_user_call_session?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setTransactions(result.data)
         }else{
            setTransactions([...transactions, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         //setProcess(true)
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
		getcallsessionLog(page)
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getcallsessionLog(1);
	}

	const openWithdrawModal = (data) =>{
		setOpenwithdraw(true);
		setSessioninfo(data);
	}

	const statusbadge = (status) =>{
		if(status == 'declined'){
			return(<Badge text={status} color="bg-red-400 " />);
		}
		if(status == 'inprogress'){
			return(<Badge text={status} color="bg-yellow-400 " />);
		}

		if(status == 'doctor_end'){
			return(<Badge text={status} color="bg-accent" />);
		}

		if(status == 'initiated'){
			return(<Badge text={status} color="bg-orange-400" />);
		}

		if(status == 'end'){
			return(<Badge text={status} color="bg-accent" />);
		}
		
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400">My Call Sessions</p>
						{transactions && transactions.length ?
						<>
						{transactions && transactions.length && 	
							<InfiniteScroll
							dataLength={transactions.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}							
							scrollThreshold={0.7}
							>
							<div className="grid  gap-4 divide-y">
							{
								transactions.map((item, key) => (
									<>
									<div className="flex py-2">
											<><div className="text-accent w-1/2">
												 <Link to={`${Links.EXPERT}/${item?.expertinfo?.slug}`}>
												 <div className="flex w-full flex-wrap justify-start w-1/2">
	                                   <div className="h-14 overflow-hidden shadow rounded-full w-14 h-auto align-top border-none flex-wrap flex">
	                                    {item?.expertinfo?.profile_image
	                                       ?
	                                       <LazyLoadImage src={`${BACKEND_URL}${item?.expertinfo?.profile_image}`} className="object-cover w-full" alt={item.name}  />
	                                       :
	                                       <LazyLoadImage src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} alt={item.product_name} className="shadow rounded-full max-w-full h-auto align-middle border-none" sizes="100vw" />
	                                       }
	                                   </div>
	                                   <div className="ml-2 flex items-center">{item?.expertinfo?.name}</div>
                                 	</div> 
                                 	</Link>
												<div className="text-gray-400 text-sm">{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</div>
												{item.start_time &&
												<div className="text-gray-400 text-sm">Start Time: {moment(item.start_time).format('DD/MM/YYYY hh:mm A')} </div>
												}
												{item.end_time &&
												<div className="text-gray-400 text-sm">End Time: {moment(item.end_time).format('DD/MM/YYYY hh:mm A')} </div>
											 }
												<div className="text-gray-400 text-sm">Call Time: {item.call_time}</div>
											</div>
											<div className="text-black w-1/2 text-right font-medium flex justify-end items-center">
												<div className="w-1/2 align-end flex flex-col">
												{item.session_status == 'end' && item?.rating_id <= 0?
													<Button onClick={()=>openWithdrawModal(item)}>Rating Session</Button>
													:
													null
												 	}
													<div>
													 {statusbadge(item.session_status)}
													 {item?.rating_id > 0 && item?.rating > 0 ?
													 	<div className="flex justify-end mt-5">{showRating(item?.rating)}</div>
													 	:null
													 }
													</div>
												</div>
											</div>
											</>
									</div>
									</>
								))
							}
							</div>
							</InfiniteScroll>	
							}
							</>
							:
							<NoRecords message="Sorry, No Call sessions Yet :(" />
						}

					</div>
				</div>
				<ModalView open={openwithdraw} onClose={closeWithdraw}>
				<SessionReviewFrm closeWithdraw={closeWithdraw} sessioninfo={sessioninfo}  />
				</ModalView>
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default MyCallSession