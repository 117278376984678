import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'

import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'
import { BiRupee ,BiMask ,BiTimer } from "react-icons/bi";
import { TfiShield } from "react-icons/tfi";
const About = (props) => {
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/about`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/about`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true" />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <>
         <section className="mx-auto w-full max-w-1920 bg-light py-4 px-4 lg:py-2 lg:px-8 xl:py-10 xl:px-16 2xl:px-20 bg-no-repeat bg-cover"  >
            <h1 className="text-heading   ">
            {details?.title}
            </h1>
           {/* <div className="mx-auto leading-loose text-white lg:w-[55%] text-center">
               <p>At HealthLogist, we provide an online counseling and emotional support platform designed to foster mental wellness. It anonymously connects you with the right expert from our panel consisting of psychologists, psychotherapists, counselors, life coaches and career coaches, who understand you and guide you through completely confidential individual sessions.</p>
               <p className="pt-5">The HealthLogist strives to build a comprehensive mental health ecosystem, offering tailored treatment and care plans for various conditions of mental health.</p>
            </div>*/}
         </section>
         <section className="mx-auto w-full bg-light pb-16 h-full items-stretch px-4 lg:px-8 xl:px-16 2xl:px-20">
               <div className="flex flex-col lg:flex-row h-full   w-full justify-start items-start ">
                  <div className="w-full lg:w-1/2 relative h-full ">
                      <div className="relative w-[80%] h-full ">
                         <img src={`${IMG_URL}support/about1.jpg`} className=" rounded-xl object-cover  "  />
                       </div>
                        <div className="absolute w-[80%] right-0 bottom-[-8rem] sm:bottom-[-10rem] lg:bottom-[-17rem]">
                         <img src={`${IMG_URL}support/about2.jpg`} className=" rounded-xl object-cover "  />
                       </div> 
                  </div>
                  <div className="w-full lg:w-1/2 px-10 text-body-dark h-full pt-28 sm:pt-32 lg:pt-0">
                         <h2 className="uppercase">Why HealthLogist</h2>
                           <p>At HealthLogist, we provide an online counseling and emotional support platform designed to foster mental wellness. It anonymously connects you with the right expert from our panel consisting of psychologists, psychotherapists, counselors, life coaches and career coaches, who understand you and guide you through completely confidential individual sessions.</p>
                           <p className="pt-5">The HealthLogist strives to build a comprehensive mental health ecosystem, offering tailored treatment and care plans for various conditions of mental health.</p>
                  
                         <div className="w-full px-0 pt-5 pb-16">
                  
                           <div className="flex flex-col justify-between align-center  ">
                              <div className="w-full  flex justify-center align-center flex-row">
                                 <div className="flex justify-center align-center"><TfiShield size={40} /></div>
                                 <div className="px-2 mb-6">
                                    <div className="flex justify-start align-start  text-2xl">Private & Confidential</div>
                                    <div className="text-left ">Your privacy is our priority, everything remains strictly confidential.</div>
                                 </div>
                              </div>

                              <div className="w-full  flex justify-center align-center flex-row">
                                 <div className="flex justify-center align-center"><BiMask size={50} /></div>
                                 <div className="px-2 mb-6">
                                    <div className="flex justify-start align-start  text-2xl">Anonymous Discussions</div>
                                    <div className="text-left ">Engage in completely anonymous discussions with our experts for secure, judgment-free support.</div>
                                 </div>
                              </div>
                            <div className="w-full  flex justify-center align-center flex-row">
                                 <div className="flex justify-center align-center"><BiTimer size={50} /></div>
                                 <div className="px-2 mb-6">
                                    <div className="flex justify-start align-start  text-2xl">Round The Clock Support</div>
                                    <div className="text-left ">Our mental healthcare services are available to you anytime, anywhere, seven days a week.</div>
                                 </div>
                              </div>
                           </div>
                        
                        </div>
                  </div>
               </div>

              <div className="mx-auto w-[100%] border-2 border-accent text-center text-body-dark text-3xl px-16 py-10 rounded-lg leading-normal">
               It's okay to not be okay. Just remember, you're not alone, and help is always available.
            </div>
            {/**/}
         </section>
         <section className="mx-auto w-full text-white text-sm text-center max-w-1920 bg-accent py-5 px-4 lg:px-8 xl:px-16 2xl:px-20">
            <div>Disclaimer: We are not a medical service or suicide prevention helpline. If you are feeling suicidal, we would suggest you immediately call up a suicide prevention helpline.</div>
         </section>
         </>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default About