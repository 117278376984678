
import {APP_URL, IMG_URL, amount_format,discount} from 'components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";


import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube , Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

const supportData = [
  {
    img:'support/sad.png',
    title: 'Depression',
    desc:'Are you feeling stuck in your sadness, struggling to see a way forward? We\'re here to help.',
  },
  {
    img:'support/emotion.png',
    title: 'Social Anxiety',
    desc:'Do social situations make you feel anxious and nervous? We\'re here to help you handle them with more confidence.',
  },
  
  {
    img:'support/cigarette.png',
    title: 'Alcohol Addiction',
    desc:'Is alcohol getting in the way of living your life to the fullest? The right support is available for you.',
  },
  {
    img:'support/bipolar.png',
    title: 'Bipolar Disorder',
    desc:'Do you go through extreme highs and lows, feeling really happy one moment and deeply sad the next? We\'re here to provide the care you need.',
  },
  {
    img:'support/fear.png',
    title: 'Generalised Anxiety Disorder (GAD)',
    desc:'Do you often feel anxious, restless, or uneasy? We\'re here to help you find better ways to cope.',
  },
  {
    img:'support/ocd.png',
    title: 'Obsessive Compulsive Disorder (OCD)',
    desc:'Are unwanted thoughts causing anxiety and leading to unhelpful habits? There are ways to cope, and we can help.',
  },  
  
]




const HomeSlider = () => {

   return(
     <div className="">
     <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube , Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false, 
        pauseOnMouseEnter: true,
      }}
      className='swiper-transition' //add CSS class
      speed={2000}
     
    >
        <SwiperSlide className="" >
        <div className="h-[600px] px-4 py-4 sm:py-16  sldimage bg-center bg-cover" style={{backgroundImage: `url('${IMG_URL}support/slider2.png') , linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)` , Zindex:40}} >
          <div className="sldimageinner"></div>
          <div className="bg-cover w-full md:w-1/2 bg-center h-full relative"  >
                   <div className="px-10 py-3">
                   
                    <h2 className="text-5xl flex justify-center align-center leading-tight text-white  text-5xl flex justify-center align-center leading-tight text-white  font-semibold uppercase">
                    Speak with Mental Health Experts and Therapists
                    </h2>
                    <p className=" flex justify-center align-center mt-4 sm:mt-10 text-white">
                      Sometimes all you need to feel better is someone who really understands. Connect with our experts for 24/7 professional help at an affordable price.
                    </p>
                    <a data-variant="normal"  href={Links.TRACk_MENTAL_HEALTH}
                            className="mt-6 inline-flex text-2xl items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent-hover  text-light border-2 border-white hover:bg-transparent px-3 py-6 h-9 text-sm font-semibold uppercase" >Track Mental Health</a>
  
                    </div>
                </div>
          </div>
        </SwiperSlide>
         <SwiperSlide className="" >
        <div className="h-[600px] px-4 py-16  sldimage bg-center bg-cover" style={{backgroundImage: `url('${IMG_URL}support/slider4.jpg') , linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)` , Zindex:40}} >
          <div className="sldimageinner"></div>
          <div className="bg-cover w-full md:w-2/3  bg-center h-full relative"  >
                   <div className="px-10 py-3">
                   
                    <h2 className="text-5xl flex justify-center align-center leading-tight text-white  text-5xl flex justify-center align-center leading-tight text-white  font-semibold uppercase">
                    Curious about your mental well-being? 
                    </h2>
                    <h4 className=" flex justify-start align-start leading-tight text-white  text-2xl flex font-semibold  leading-tight text-white  font-semibold uppercase">
                    Take this quick, insightful test and discover more about yourself!
                    </h4>
                    <a data-variant="normal"  href={Links.TRACk_MENTAL_HEALTH}
                             className="mt-6 inline-flex text-2xl items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent-hover  text-light border-2 border-white hover:bg-transparent px-3 py-6 h-9 text-sm font-semibold uppercase" >Track Mental Health</a>
  
                    </div>
                </div>  
          </div>
        </SwiperSlide>
         <SwiperSlide >
          <div  style={{backgroundImage: `url('${IMG_URL}support/slider3.png') , linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)`}} className="h-[600px]  px-4 py-16 sldimage bg-center bg-cover">
                    <div className="sldimageinner"></div>

          <div className="bg-cover w-full md:w-1/2 bg-center h-full relative"  >
                 <div className="px-10 py-3">
                 
                  <h2 className="text-5xl flex justify-center align-center leading-tight text-white   uppercase font-semibold uppercase">
                  Break Free from the Weight of Depression
                  </h2>
                  <p className=" flex justify-center align-center mt-10 text-white">
                  A journey toward healing, highlighting the importance of receiving understanding and empathetic support along the way. It conveys a sense of care, guidance, and encouragement for overcoming challenges and finding peace.
                  </p>
                  <a data-variant="normal"  href={Links.TRACk_MENTAL_HEALTH}
                             className="mt-6 inline-flex text-2xl items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent-hover  text-light border-2 border-white hover:bg-transparent px-3 py-6 h-9 text-sm font-semibold uppercase" >Track Mental Health</a>
  
                  </div>
              </div>
          </div>
        </SwiperSlide>
      
    </Swiper>
    </div>
   )
}



export default HomeSlider;