import React, {useState, useEffect , useRef} from "react";
import { Link , useNavigate } from "react-router-dom";

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  

import {APP_URL, APP_NAME , IMG_URL , toast_anim_error , loadScript , RAZOR_KEY} from 'components/config/Common'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	amount: Yup.number()
      .typeError('Amount must be a number')
      .positive('Amount must be positive')
      .required('Amount is required'),
});


let defaultValues = {  
	amount: '',
};

const AddMoneyToWallet = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
   const [enabled, setEnabled] = useState(false)
   const [productionurl , setProductionurl] = useState('');
	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
  	});

  	const refphone = useRef(null);
  	   const navigate = useNavigate();

	useEffect(() => {
		getMyProfile();
	},[access_token])
	
 const getMyProfile = () => {
     
      fetch(`${API_ENDPOINT}/user_me`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${access_token}`, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
        
         setUser_me(result.data)
         
      })
   }

  const handleSelect = (val,name) => {  
		console.log('cat val======',val,name.name)
		setError(name.name, null)
		setValue(name.name, val, true);
		
	}

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Add Money To wallet - ${APP_NAME}`,
		description: `Add Money To wallet - ${APP_NAME}`,
	}


	const onSubmit = async(values) => {
		console.log('value', values)
		setLoading(true)		
		

		  const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
         //alert("Razorpay SDK failed to load. Are you online?");
        // toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      }
      // New code if grand total is greater than 500000      
          
      fetch(`${API_ENDPOINT}/razor_payment_order`,{
         method: 'POST',
         headers : {           
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+access_token, 
         },
         body: JSON.stringify({
            amount:values?.amount*100,
         })
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
        
         var options = {
            "key": RAZOR_KEY, // Enter the Key ID generated from the Dashboard
            "amount": result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "HEALTHLOGIST",
            "description": "Purchese Service for Mental Health",
            "image": "https://www.healthlogist.com/backend/public/storage/setting_images/healthlogist-logo.jpg",
            "order_id": result.data.order_id,
            "handler": function (response){
               console.log("response",response);
               const data = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
               };
               console.log("payment Response Data",data);
               console.log("payment Request Data",result.data.tr_id);

               

               if(response.razorpay_payment_id){
                  
                  handlePlaceOrder(data,result.data.tr_id,result.data.amount);    
                  
               }

            },
            "prefill": {
               "name": user_me.name,
               "email": user_me.email,
               "contact": user_me.phone_no
            },
            
            "theme": {
               "color": "#009f7f"
            }
         };

         const rzp1 = new window.Razorpay(options);
         /* rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
         }); */
         rzp1.open();
      })
	}


	const handlePlaceOrder = (data,transactionId,amount) => {
      
         setLoading(true)
         fetch(`${API_ENDPOINT}/place_order`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': 'Bearer '+access_token, 
            },
            body: JSON.stringify({
               transactionId:transactionId,
               amount:amount,
               payment_response:data,
            })
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            console.log('after pay==', result)
            setLoading(false)
            if(result.status === 1){
               //getCartItems();
               return navigate(`/dashboard`, { replace: true });
            }
         })
     
   }


	

	return (
		<ScrollPageTop>
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Add Money to Wallet</p>
					
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex w-full">
								 <Input                
	                            label={`Amount`}
	                            {...register('amount')}
	                            variant="outline"
	                            type="number"
	                            className="mb-5 w-1/2"
	                            error={errors.amount?.message}
	                            required
	                        />
	                        <div className="flex w-1/2 align-center justify-center">
						    		</div>
	                      </div>
								<div className="flex">
									<div className="flex w-1/2 align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex uppercase items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Add</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}
									</div>
								</div>

								{productionurl ? 
                     (<iframe src={productionurl} id="paymentFrame" width="482" height="450" frameborder="0" scrolling="No" border="1" ></iframe>)
                     :
                     (null)
                     }
							</form>
					</div>
				</div>
      </ScrollPageTop>
   );
};

export default AddMoneyToWallet