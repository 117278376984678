import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import TextArea from 'components/ui/text-area.jsx'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
   message: Yup.string()
        .required('Question is required'),
});


let defaultValues = {  
	amount: '',
	visibility_opt:'public',
};

const AskQuestionForm = (props) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id, setUserId] = useState(localStorage.getItem('user_id'))

	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
		
  	});


	//
	
	
	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
		let inputN = {
			...input,
			user_id:user_id,
			expert_id:props?.expert_id,
		}
     
      setLoading(true)
      axios.post(`${API_ENDPOINT}/ask_question_save`,inputN,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeWithdraw();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })

		
	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-2 dark:text-zinc-400">Ask Question</p>
						<form onSubmit={handleSubmit(onSubmit)}>
								 <TextArea
		                     label=''
		                     {...register('message')}                        
		                     variant="outline"
		                     className="mb-5 w-full"
		                     error={errors.message?.message}
		                     required
		                  />
		                    <div className="mb-5 w-full">
			                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-license"   {...register('visibility_opt')}  type="radio" value="public" name="visibility_opt"  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"  />
			                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Public </label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-id" type="radio" {...register('visibility_opt')}  value="private" name="visibility_opt"  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Private</label>
			                          </div>
			                      </li>
			                  </ul>
			                  </div>
								<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto uppercase">Ask Now</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default AskQuestionForm