import React, {useState, useEffect, useRef} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT , EXPERT_LABEL} from 'components/config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "components/custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow  } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../../routes/PathLink'

import { useSearch } from 'contexts/search/search.context.tsx';
import {useSpeclizationFilter} from 'contexts/speclization-filter/speclization-filter.context.tsx';

import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from 'pages/common/NoRecords'
import Seo from 'components/seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from 'components/custom-icons'
import Button from 'components/ui/Button.tsx'
import DoctorCard from 'components/cards/DoctorCard'
import LanguageFilter from './filter/LanguageFilter';
import Select from 'components/ui/select/select.tsx';
import GenderFilter from "./filter/GenderFilter";
import ModalView from '_utils/ModalView';
import DoctorFilterDrawer from 'components/doctor/filter/doctor-filter-drawer';
const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false
/*const sortOptions = [
   {
    'id':'rating',
    'label':'Rating',
  },
  {
    'id':'session_price',
    'label':'Price Per Session',
  },
  {
    'id':'online',
    'label':'Online',
  },
];
*/

const DoctorsFront = () => {
   const [process, setProcess] = useState(false)
      const [user_id , setUser_id] = useState(localStorage.getItem('user_id'));

   const [doctors,setDoctors]= useState([]);
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [activeKey, setActiveKey] = useState(['ct-scan']);
   const navigate = useNavigate();
   const { searchTerm } = useSearch();
   const {specTerm,setSpecTerm,searchgender,searchlangues,searchlangueslen, setSearchlangues , 
   setSearchlangueslen,
   searchonlineonly,
   setSortpopular,
   setSearchonlineonly,
   sortpopular, sortsessionprice , setSearchgender , searchlangueslbl , setSearchlangueslbl} = useSpeclizationFilter();
   //const {searchgender,setSearchgender} = useSpeclizationFilter();
   /*  setSearchlangues,searchlangueslen,
      setSearchlangueslen,
   searchonlineonly,
      setSearchonlineonly,
    sortpopular ,
      setSortpopular,
   sortsessionprice ,
      setSortsessionprice
      */
      //const {} = useSpeclizationFilter();

   const myRef = useRef(null)
   const [values, setValues] =  useState([MIN, MAX])
   const [sortparam,setSortparam]= useState('');
   const [gender,setGender] = useState(null);

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.CATEGORY}`,
      title:  `${EXPERT_LABEL} - ${APP_NAME}`,
      description:`${EXPERT_LABEL}`, 
   })

   const [languages, setLanguages] = useState([]);
   const [languageslen, setLanguageslen] = useState(0);

   const [hasMore, setHasMore] = useState(true)
   
   const {slug, sub_slug} = useParams()
   // console.log(useParams())

   useEffect(() => {
       getDoctors(1);
   },[searchTerm,sortparam,searchgender,searchlangues,searchlangueslen,specTerm,
      searchonlineonly,sortpopular,sortsessionprice]);

   const getDoctors = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      let languagesstr = '';
      let sortparamstr ='';
      let genderstr ='';
      let spelicationstr = '';
      let searchonlineonlystr = '';
      let sortpopularstr = '';
      let sortsessionpricestr ='';
      let user_idstr = '';
      if(searchlangues){
         languagesstr = '&languages='+searchlangues;
      }
      if(searchTerm){
         search = '&search='+searchTerm;
      }

      if(sortparam){
         sortparamstr = '&sortparam='+sortparam;
      }

      if(searchgender){
         genderstr = '&gender='+searchgender;
      }
      if(specTerm?.id){
         spelicationstr = '&spelication='+specTerm?.id;
      }
      if(searchonlineonly){
         searchonlineonlystr = '&isonline='+searchonlineonly;
      }

      if(sortpopular){
         sortpopularstr = '&popular='+sortpopular;
      }

      if(sortsessionprice){
         sortsessionpricestr = '&session_price='+sortsessionprice;
      }
      if(user_id && user_id > 0){
         
         user_idstr ='&user_id='+user_id;
      }
      
      fetch(`${API_ENDPOINT}/get_experts_front?${paginate}${languagesstr}${search}${sortparamstr}${genderstr}${spelicationstr}${searchonlineonlystr}${sortpopularstr}${sortsessionpricestr}${user_idstr}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat Doctors======',result)
         //setProducts(result.data)
         if(pg === 1){
            setDoctors(result.data)
         }else{
            setDoctors([...doctors, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
      })
   }

   const fetchData = () => {
      console.log('page==',page)
		getDoctors(page);
	}

   /*const getActiveKey = (key) => {
      console.log('getActiveKey======',key[0])
      setPage(1)
      if(key[0]){
         navigate(`${Links.CATEGORY}/${key[0]}`);
      }else{
         
         navigate(`${Links.CATEGORY}`);
      }

      const filteredData = category.filter((item, i) =>  {
         if(item.slug == key[0]){
            item.isActive = true;
         }else{
            item.isActive = false;
         }
         return item
     });
     //console.log('getActiveKey======',filteredData)
     setCategory(filteredData)
     
   }
   */
   

   const handleSortSelect = (val) =>{
      console.log("SORT parameter",val?.id);
      setSortparam(val?.id);
   }

    const clearSpelization = () =>{
      //console.log("SORT parameter",val?.id);
      setSpecTerm(null);
   }


   const handleLanFilter = (languagesp) => {
      if(languagesp.checked == true){
         languages.push(languagesp.id);
      }else{
         languages.pop(languagesp.id);
      }

      setLanguages(languages);
      setLanguageslen(languages.length);
      console.log('language list ======',languages);
   }

     const handleGenderFilter = (genderval) => {
      setGender(genderval);
      console.log(genderval);
   }



   const openfilter = () =>{

   }

   const closefilter = () =>{

   } 
   const claerSeleted = () => {
      setSearchgender('');
      setSearchlangues([]);
      setSearchlangueslen(0);
      setSearchonlineonly('no');
      setSortpopular('no');
      //setSortprice(null);
   }


   return(
      <ScrollPageTop>
      {/* <ReactSEOMetaTags
         render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
         website={{ ...metaData }}
		/> */}
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
        
      <div className="flex flex-col lg:flex-row bg-gray-100 dark:bg-gray-800  py-3">
			
				<main  id="product-list" className="block w-full lg:mt-1 xl:overflow-hidden ltr:xl:pl-5 ltr:xl:pr-5 rtl:xl:pr-5 rtl:xl:pl-5">
             <div className="flex w-full">
             <div className="flex items-center w-1/2">
             {specTerm?.label ?
               <Button 
                  size='small'
                  variant='normal'
                 onClick={clearSpelization}
                 className="bg-gray-800 rounded-full px-4 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]"
               >
               <CloseIcon className="w-4 h-4 pt-0.5 mr-0.5" /> {specTerm?.label}
               </Button>
               :
               null
            }
            {searchgender ?
               <Button 
                  size='small'
                  variant='normal'
                  className="ml-2 bg-gray-800 rounded-full px-4 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]"
               >
                {searchgender}
               </Button>
               :
               null
            }
             {searchonlineonly == 'yes' ?
               <Button 
                  size='small'
                  variant='normal'
                  className="ml-2 bg-gray-800 rounded-full px-4 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]"
               >
                Online Only
               </Button>
               :
               null
            }
            {searchlangues && searchlangues.length > 0 ?
            <>
               { searchlangueslbl && searchlangueslbl.map((item, key) => (
                      <Button 
                        size='small'
                        variant='normal'
                        className="ml-2 bg-gray-800 rounded-full px-4 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]"
                     >
                      {item}
                     </Button>
                  ))  
                }               
               </>
               :
                null
            }
            
            { (searchlangues && searchlangues.length > 0) ||
              (searchonlineonly == 'yes') || (searchgender)  ?
               (<button class='my-8 float-right px-5 py-2 bg-gray-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none ml-3' onClick={claerSeleted}>Clear All</button>)
               :null
            }
            </div>
            <div className="mt-5 flex justify-end w-1/2">
             <DoctorFilterDrawer />

               {/* <Label>Sort <span className="text-red-500"> </span></Label>*/}
                     {/* <Select
                        name="sort_options"                            
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.id}                        
                        options={sortOptions}
                        onChange={handleSortSelect}                           
                     /> */}
               </div>
          </div>
            {
            process
            ?
					<div name="grid"  className="px-4 xl:px-0">
						<div className="w-full pb-6">
                  { doctors && doctors.length && totalRec > 0 ?
                  <InfiniteScroll
							dataLength={doctors.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}
                     scrollThreshold={0.7}
							>
							<div layout className="grid grid-cols-[repeat(auto-fill,minmax(330px,1fr))] gap-3">
                     { doctors && doctors.map((item, key) => (
                           <DoctorCard item={item} key={`product-${item.id}`} />
                        ))  
                      }               
							</div>      
                  </InfiniteScroll>               
                  :
                    <NoRecords message="Sorry, No Experts Found :(" />
                  
                  }
							{/* <div className="mt-8 flex justify-center lg:mt-12"><button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 text-sm font-semibold md:text-base">Load More</button></div> */}
						</div>
					</div>
               :
                  <div className="w-full h-96 flex items-center justify-center">
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
                  </div>
               }
				</main>
			</div>

      <Footer />
      </ScrollPageTop>
   )
}

export default DoctorsFront