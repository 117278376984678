import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT , amount_format} from 'components/config/Common'


import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import AddBankDetailForm from "components/wallet/AddBankDetailForm";
import AddUPIForm from "components/wallet/AddUPIForm";

import Badge from "components/ui/badge/badge";

const BankDetailsList = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
   const [openupifrm , setOpenupifrm] = useState(false);

	useEffect(() => {
		getBankAccountList(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My Bank Accounts - ${APP_NAME}`,
		description: `My Bank Accounts - ${APP_NAME}`,
	}

	
	const getBankAccountList = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/bank_account_list?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setTransactions(result.data)
         }else{
            setTransactions([...transactions, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         //setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
		getBankAccountList(page)
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getBankAccountList(1);
	}

	const openWithdrawModal = () =>{
		setOpenwithdraw(true);
	}

	const openUpiFrmModal = () =>{
		setOpenupifrm(true);
	}
	const closeUpifrm = () =>{
		setOpenupifrm(false);
		getBankAccountList(1);
	}

	return (
		<ScrollPageTop>
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400">My Bank Account</p>
						<div className="flex">
						{transactions && transactions.length > 0 && transactions[0].account_no != '' ?
							null
							:
							(<div className="flex mb-5 ">
								<div className="w-1/2 align-end flex">
								 <Button onClick={openWithdrawModal}>Add Bank Account</Button>
								</div>
							</div>)
							}
						 {transactions && transactions.length > 0 && (transactions[0].upi_id != '' && transactions[0].upi_id != null ) ?
						 	null
							:
							(<div className="flex mb-5 ml-4">
								<div className="w-1/2 align-end flex">
								 <Button onClick={openUpiFrmModal}>Add UPI Id</Button>
								</div>
							</div>)
						  }
						</div>
						{transactions && transactions.length?
						(<>
						{transactions && transactions.length && 	
							<InfiniteScroll
							dataLength={transactions.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}							
							scrollThreshold={0.7}
							>
							<div className="grid  gap-4 divide-y">
							{
								transactions.map((item, key) => (
									<>
									<div className="flex py-2">
										<>	
										<div className="text-accent w-1/2">{item.account_no}
											<div className="text-gray-400 text-sm">{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</div>
										</div>
										<div className="text-black w-1/2 text-right font-medium flex justify-end items-center">{item.account_name}<br />{item.bank_name}</div>
										</>
									</div>
									{item?.upi_id ?
										(<div className="flex py-2">
										<div className="text-accent w-1/2">
										{item?.upi_id}
										</div>
										</div>)
										:null
									}
									</>
								))

							}
							</div>
							</InfiniteScroll>	
							}
							</>)
							:
							null
						}

					</div>
				</div>
				<ModalView open={openwithdraw} onClose={closeWithdraw}>
				<AddBankDetailForm closeWithdraw={closeWithdraw} />
				</ModalView>

				<ModalView open={openupifrm} onClose={closeUpifrm}>
					<AddUPIForm closeUpifrm={closeUpifrm} />
				</ModalView>
		
      </ScrollPageTop>
   );
};

export default BankDetailsList