import React, {useState, useEffect , useRef} from "react";
import { Link , useParams, useNavigate } from "react-router-dom";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {BACKEND_URL , APP_URL, APP_NAME , IMG_URL , LIMIT , amount_format ,showRating , replaceWithBr, date_format} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import AskQueAnswerForm from "components/questions/AskQueAnswerForm";
import { ImReply } from "react-icons/im";

const QuestionsWithAns = () => {
	const navigate = useNavigate();

	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
   const [sessioninfo,setSessioninfo] = useState(null);
   const [questionDetails,setquestionDetails] = useState(null);
   const [openanswer,setOpenanswer] = useState(false);
   const [questionTxt,setQuestionTxt] = useState('');
   const [question_id,setQuestion_id] = useState(0);

   const {slug} = useParams();
	
	useEffect(() => {
		getcallsessionLog(1);
		getQuestionInfo();
	},[slug]);

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Question - ${APP_NAME}`,
		description: `Question - ${APP_NAME}`,
	}

	const getQuestionInfo = () =>{
	
		fetch(`${API_ENDPOINT}/questionInfoBySlug/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data);
			setquestionDetails(result?.data);
		});
	}

	const getcallsessionLog = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/questionNanswer/${slug}?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setTransactions(result.data)
         }else{
            setTransactions([...transactions, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
      
		})
	}

	const fetchData = () =>{
		getcallsessionLog(page)
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getcallsessionLog(1);
	}

	const openWithdrawModal = (data) =>{
		setOpenwithdraw(true);
		setSessioninfo(data);
	}

	const statusbadge = (status) =>{
		if(status == 'inactive'){
			return(<Badge text={status} color="bg-red-400 " />);
		}
		
		if(status == 'active'){
			return(<Badge text={status} color="bg-accent" />);
		}
		
	}
const openAnswerBox = (quest_txt,q_id) =>{
	 setQuestionTxt(quest_txt);
	 setOpenanswer(true);
	 setQuestion_id(q_id);
}
const closeAnswer = () =>{
		setOpenanswer(false);
	}
	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         	<Header hidesearch="true" />
				<div className="w-full bg-light">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400">Discussion Question - {questionDetails?.qans_text}</p>
						{user_type && user_type == 'expert' && <Link to="" onClick={()=>{openAnswerBox(questionDetails?.qans_text,questionDetails?.qans_id); }} className="h-18 mb-6 flex justify-start items-center	">
							<ImReply />
						</Link>}
						{transactions && transactions.length ?
						<>
						{transactions && transactions.length && 	
							<InfiniteScroll
							dataLength={transactions.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}							
							scrollThreshold={0.7}
							>
							<div className="grid  gap-6 divide-y-10 justify-start items-start ">
							{
								transactions.map((item, key) => (
									<>
									<div className="flex flex-col sm:flex-row py-2 px-2 border-[1px] border-accent ">
									<div className="w-full sm:w-[10%] p-2 rounded-tr-lg mt-0 gap-1 text-accent flex flex-row sm:flex-col justify-start items-start ">
                              <div><img src={`https://ui-avatars.com/api/?name=${item?.expert?.name}&background=53746C&color=FFFFFF`} className="w-12 rounded-full" /></div>
                              <div>{item?.expert?.name}</div>
                              <div className="text-body text-sm ">{date_format(item?.created_at)}</div>
                              
                           </div>
									<>
									<div className="text-body w-full px-2">
										<div className="leading-loose text-body-dark" dangerouslySetInnerHTML={{__html:replaceWithBr(item?.qans_text)}} />
									</div>
									</> 
									</div>
									</>
								))
							}
							</div>
							</InfiniteScroll>	
							}
							</>
							:
							<NoRecords message="Sorry, No Questions Response Yet :(" />
						}
					</div>
				</div>
				 <ModalView open={openanswer} onClose={closeAnswer}>
          <AskQueAnswerForm closeWithdraw={closeAnswer} question_txt={questionTxt} question_id={question_id}  /> 
        </ModalView>
			      <Footer />
      </ScrollPageTop>
   );
};

export default QuestionsWithAns