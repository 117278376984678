import { useState ,InputHTMLAttributes ,useRef ,useEffect  } from 'react';

import cn from 'classnames';
import { SearchIcon, CloseIcon } from 'components/custom-icons'

import { Link} from "react-router-dom";
import {APP_URL, API_ENDPOINT} from 'components/config/Common'
import {useSpeclizationFilter} from 'contexts/speclization-filter/speclization-filter.context.tsx';


export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  variant?: 'minimal' | 'normal' | 'with-shadow' | 'flat';
  onSubmit: (e: any) => void;
  onClearSearch: (e: any) => void;
  onSetValue:()=>void;
}

const classes = {
  normal:
    'bg-light ltr:pl-6 rtl:pr-6 ltr:pr-14 rtl:pl-14 ltr:rounded-tr-none rtl:rounded-tl-none ltr:rounded-br-none rtl:rounded-bl-none  border ltr:border-r-0 rtl:border-l-0 border-transparent focus:border-accent',
  minimal:
    'search-minimal bg-gray-100 ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border border-transparent focus:border-accent focus:bg-light',
  flat: 'bg-white ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border-0',
  'with-shadow':
    'search-with-shadow bg-light ltr:pl-10 rtl:pr-10 ltr:pr-12 rtl:pl-12 ltr:md:pl-14 rtl:md:pr-14 focus:bg-light border-0',
};

const SearchBox: React.FC<Props> = ({
  className,
  label,
  onSubmit,
  onClearSearch,
  variant = 'normal',
  value,
  onSetValue,
  submitRef,
  onSpeclizationSelect,
  ...rest
}) => {
   
     const {specTerm,setSpecTerm} = useSpeclizationFilter();
    
   //let [value,Setvalue] = useState(value);
   const handletextClick = (textsearch) => {
      //console.log("LINK CLICK",textsearch);
      onSetValue(textsearch);
   }


   const handleSpecClick = (searchspec) => {
      console.log("LINK CLICK",searchspec);
      setSpecTerm(searchspec);
      onSpeclizationSelect();
   }

   const [specalization,setSpecalization] = useState([]);

   useEffect(() => {      
      getSpecalization()
   },[])

    const getSpecalization = () => {
      fetch(`${API_ENDPOINT}/get_specalization_list`,{
      method: 'GET',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json', 
      }
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      console.log('side bar categories======',result)
      setSpecalization(result.data)
    })
   }

  return (
    <form  onSubmit={onSubmit} className={cn('w-full', className)}>
        <div className="w-full grid items-center content-center bg-no-repeat bg-cover bg-center py-10"  style={{backgroundImage:`url(${APP_URL}/img/slider-img01.jpg)`,position:'relative'}}>
           <div className="searchoverlay"></div>
            <div className="w-full md:w-3/5 justify-self-center ">
                <div class=" px-10 pt-5   ">
                  <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                    <input
                      type="search"
                      class="bg-white relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300  bg-clip-padding px-3 py-[0.75rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-accent focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-accent"
                      placeholder="Search Doctors"
                      aria-label="Search"
                       value={value}
                        {...rest}
                       data-variant={variant}
                      aria-describedby="button-addon1" />

                    <button
                      class="relative z-[2] flex items-center rounded-r bg-accent px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                      type="submit"
                      id="button-addon1"
                      data-te-ripple-init
                      data-te-ripple-color="light" 
                      ref={submitRef}
                      >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
               </div>
        </div>
        <div className="flex justify-center mb-3 z-50 items-center" ><h1 className="text-white text-4xl text-center">What do you want to discuss about?</h1></div>
        <ul className="list-none px-10  flex flex-wrap gap-5 items-center justify-self-center mb-3 z-10 h-auto md:h-full overflow-hidden md:overflow-auto">
          { specalization && specalization.length ? 
            <>
              {specalization && specalization.map((item, key) => (
               <li><Link
            to="#"
            onClick={()=>handleSpecClick(item)}
            className="text-2xl flex items-center font-semibold   text-heading no-underline transition duration-200 text-white hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
            >
            {item.label}</Link></li>
              ))
              }
           </> 
           :
           null
          }
        </ul>
        
        </div>
    </form>
  );
};

export default SearchBox;
