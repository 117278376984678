
import {Links} from '../routes/PathLink'

export const SideMenu = () => {

   let user_type = localStorage.getItem('user_type')
   console.log('side menu type', user_type)

   if(user_type === 'user'){
      //users/doctor menu
      const menu = [{
            label:'Dashboard',
            link:Links.MYDASHBOARD,
         },
         {
            label:'Profile',
            link:Links.PROFILE,
         },
         {
            label:'My Wallet',
            link:Links.MYWALLET,
         },
          {
            label:'My Appointment Schedule',
            link:Links.MYAPPOINTMENTSUSER,
         },
         {
            label:'My Call Sessions',
            link:Links.MYCALLSESSION,
         },
         {
            label:'Ask Question',
            link:'',
            openfunc:'',
         },
         {
            label:'My Questions',
            link:Links.MYQUESTIONS,
         },
      ]
      return menu
   }
   if(user_type === 'expert'){
      const menu = [
          {
            label:"My Dashboard",
            link:Links.DOCTORDASHBOARD,
         },
         {
            label:'Profile',
            link:Links.PROFILE_DOCTOR,
         },
         {
            label:'My Wallet',
            link:Links.MYDOCTORWALLET,
         },
        {
            label:' My Appointment Schedule',
            link:Links.MYAPPOINTMENTS,
         },
         
           {
            label:' Answer User Questions',
            link:Links.ANSWERUSERQUESTION,
         },
         {
            label:"My Session Ratings",
            link:Links.DOCTORMYRATINGS,
         },
          {
            label:'My Call Sessions',
            link:Links.EXPERTCALLSESSION,
         },
          {
            label:' Set Availability',
            link:Links.EXPERTAVALIBILITY,
         },
       
         
        
        
      ]
      return menu
   }   
}