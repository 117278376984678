import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT ,amount_format} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import WithdrawMoney from "components/wallet/WithdrawMoney";
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords';
import StickerCard from "components/widgets/sticker-card";
import UpcomingAppointment from "components/appointment/UpcomingAppointment";
import TodaysAppointment from "components/appointment/TodaysAppointment";
import PendingAppointment from "components/appointment/PendingAppointment";
import ExpertStatusRadio from "components/experts/ExpertStatusRadio";
import MissedAppointment from "components/appointment/MissedAppointment";
import AskQueAnswerForm from "components/questions/AskQueAnswerForm";
import { Tab } from '@headlessui/react'
import { ImReply } from "react-icons/im";

const DoctorDashboard = () => {

	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id , setUser_id] = useState(localStorage.getItem('user_id'));
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	const [transactions,setTransactions]= useState([]);
	const [questions,setQuestions]= useState([]);

 const [page, setPage] = useState(1)
 const [totalRec, setTotalRec] = useState(0)
 const [hasMore, setHasMore] = useState(true)
 const [openwithdraw ,setOpenwithdraw] = useState(false);
 const [avaliable_status, setAvaliable_status] = useState('');
 const [opencalling,setOpencalling] = useState(false);
 const [openanswer,setOpenanswer] = useState(false);
 const [questionTxt,setQuestionTxt] = useState('');
 const [question_id,setQuestion_id] = useState(0);
 const [currentab ,setcurrentab] = useState(0);

	useEffect(() => {
		getWalletTotal();
		getAvailStatus();
		get_latest_questions();
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My  wallet - ${APP_NAME}`,
		description: `My  wallet - ${APP_NAME}`,
	}

	const getWalletTotal = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/expert_total_wallet_amount`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log("TOTAL WALLET AMOUNT ",result)
			if(result.status == 1){
				setTotalamount(result.data);
			}
		})
	}	


	const get_latest_questions = () =>{
      
      let paginate = `page=1&limit=5`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/all_questions?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			setQuestions(result.data);
			//setProducts(result.data)
		})

	}
	

	const fetchData = () =>{
	}

	const closeAnswer = () =>{
		setOpenanswer(false);
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getWalletTotal();
	}

	const openWithdrawModal = () =>{
		setOpenwithdraw(true);
	}

	const getAvailStatus = () => {
    //console.log(user_id)

    fetch(`${API_ENDPOINT}/get_availability_status`,{
      method: 'GET',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${access_token}`, 
      }
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      console.log('products======',result.data)
      if(result.status === 1){
         console.log('avail status',result.data?.availability_status);
        setAvaliable_status(result.data?.availability_status);
      }else{
        //navigate(Links.HOME);
      }
    })
  }

const closeCalling = () =>{
  setOpencalling(false);
  getAvailStatus();
}

const openExpertOnline = () =>{
  setOpencalling(true);
}
const openAnswerBox = (quest_txt,q_id) =>{
	 setQuestionTxt(quest_txt);
	 setOpenanswer(true);
	 setQuestion_id(q_id);
}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8  shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400 mb-10">Welcome Your are Logged in as {user_type}</p>
						
						<div className="container mx-auto grid mb-5 w-full mt-5 grid-cols-1 md:grid-cols-3 gap-3 grid-auto-rows: 1fr">
							<div className="w-full mb-5 flex flex-col h-full col-span-1 min-h-full">
							<Link to={Links.MYDOCTORWALLET} className="h-28	">
					            <StickerCard
					            titleTransKey="Wallet Amount"
					            subtitleTransKey="Amount available in your wallet"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white" />}
					            linkText={amount_format(totalamount)}
					            link={Links.MYDOCTORWALLET}
					          />
			          		</Link>
			          		<Button onClick={openWithdrawModal} className="mt-3 uppercase">WithDraw Money</Button>

							</div>
							<div className="w-full align-end flex flex-col ml-2 h-full col-span-1 min-h-full">
								<Link to={'#'} className="h-28	">
						            <StickerCard
						            titleTransKey="Your Current Availability Status "
						            subtitleTransKey=""
						            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white" />}
						            linkText={<span className="text-red-500 font-semibold"> {avaliable_status}</span>}
						            link={'#'}
						          />
				          		</Link>
								<Button onClick={()=>openExpertOnline()} className="mt-3 uppercase ">Update Status</Button>
							</div>
							<div className="w-full  mb-5 flex flex-col ml-2 h-full col-span-1 min-h-full">
							<Link to={'#'} onClick={()=>{setcurrentab(2); }} className="h-28	">
					            <StickerCard
					            titleTransKey="Pending Appointment"
					            subtitleTransKey="Amount available in your wallet"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white" />}
					            linkText={'10'}
					            link={'#'}
					          />
			          		</Link>
			          		</div>
						</div>

						<div className="w-full  px-2 py-2 sm:px-0">
							<Tab.Group onChange={(index) => {
						        console.log('Changed selected tab to:', index)
						        setcurrentab(index);
						      }}>
						      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1" >
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  ${currentab == 0 ? ' bg-white shadow ': '' }`}>Today's Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 1 ? ' bg-white shadow ': '' }`}>Upcoming Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 2 ? ' bg-white shadow ': '' }`}>Pending Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 3 ? ' bg-white shadow ': '' }`}>Appointment History</Tab>

						      </Tab.List>
						      <Tab.Panels>
						        <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"><TodaysAppointment /></Tab.Panel>
						        <Tab.Panel><UpcomingAppointment /></Tab.Panel>
						        <Tab.Panel><PendingAppointment /></Tab.Panel>
						        <Tab.Panel><MissedAppointment /></Tab.Panel>
						        
						      </Tab.Panels>
						    </Tab.Group>
						</div>

						<div className="w-full  px-2 py-2 sm:px-0 bg-light">
						 <div className="flex items-center justify-between px-5">
                <h1 className="text-lg text-heading sm:text-xl dark:text-body">
                	Latest Questions
                </h1>
             </div>
						{questions && questions.length ?
						<>
						{questions && questions.length && 	
							<InfiniteScroll
							dataLength={questions.length} //This is important field to render the next data
							next={fetchData}
							hasMore={false}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}							
							scrollThreshold={0.7}
							>
							<div className="grid  gap-6 divide-y-10 px-4">
							{
								questions.map((item, key) => (
									<>
									<div className="flex py-2 px-2 border-[1px] border-accent">
											<>
												<div className="text-body font-medium w-full">
												{item?.qans_text}
												<Link to="" onClick={()=>{openAnswerBox(item?.qans_text,item?.qans_id); }} className="h-28	">
													<ImReply />
												</Link>
												</div>

											</>
									</div>
									</>
								))
							}
							</div>
							</InfiniteScroll>	
							}
							</>
							:
							<NoRecords message="Sorry, No Questions  Yet :(" />
						}
						</div>
						

					</div>
				</div>
				<ModalView open={openwithdraw} onClose={closeWithdraw}>
				<WithdrawMoney closeWithdraw={closeWithdraw} />
				</ModalView>

				<ModalView open={opencalling} onClose={closeCalling}>
          <ExpertStatusRadio closeCalling={closeCalling}  /> 
        </ModalView>
        	<ModalView open={openanswer} onClose={closeAnswer}>
          <AskQueAnswerForm closeWithdraw={closeAnswer} question_txt={questionTxt} question_id={question_id}  /> 
        </ModalView>

		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default DoctorDashboard