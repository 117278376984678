import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {BACKEND_URL , APP_URL, APP_NAME , IMG_URL , LIMIT , amount_format ,showRating, replaceWithBr,date_format} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { Tab } from '@headlessui/react'
import { ImReply } from "react-icons/im";


const AllQuestions = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
   const [sessioninfo,setSessioninfo] = useState(null);
   const [currentab ,setcurrentab] = useState(0);

   const [public_question,setPublic_question]= useState([]);
   const [pagepb, setPagePb] = useState(1)
   const [totalRecpb, setTotalRecpb] = useState(0)
   const [hasMorepb, setHasMorepb] = useState(true)


   const [openanswer,setOpenanswer] = useState(false);
 const [questionTxt,setQuestionTxt] = useState('');
 const [question_id,setQuestion_id] = useState(0);

	useEffect(() => {
		getcallsessionLog(1);
		getPublicQuestion(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `All Questions - ${APP_NAME}`,
		description: `All Questions - ${APP_NAME}`,
	}


	const getcallsessionLog = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
       let visibility = '&visibility=private'
		fetch(`${API_ENDPOINT}/all_questions?${paginate}${visibility}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setTransactions(result.data)
         }else{
            setTransactions([...transactions, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         //setProcess(true)
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}
const openAnswerBox = (quest_txt,q_id) =>{
	 setQuestionTxt(quest_txt);
	 setOpenanswer(true);
	 setQuestion_id(q_id);
}

const getPublicQuestion = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      let visibility = '&visibility=public'
		fetch(`${API_ENDPOINT}/all_questions?${paginate}${visibility}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
			//public_question,setPublic_question
         if(pg === 1){
            setPublic_question(result.data)
         }else{
            setPublic_question([...public_question, ...result.data])
         }


         let nextPage = pagepb + 1;
         setPagePb(nextPage)
         setTotalRecpb(result.total)

         if(result.data.length < LIMIT){
            setHasMorepb(false)
         }else{
            setHasMorepb(true)
         }
         //setProcess(true)
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchDatapb = () =>{
		getPublicQuestion(pagepb);
	}

	const fetchData = () =>{
		getcallsessionLog(page)
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getcallsessionLog(1);
	}

	const openWithdrawModal = (data) =>{
		setOpenwithdraw(true);
		setSessioninfo(data);
	}

	const statusbadge = (status) =>{
		if(status == 'inactive'){
			return(<Badge text={status} color="bg-red-400 " />);
		}
		
		if(status == 'active'){
			return(<Badge text={status} color="bg-accent" />);
		}
		
	}
	



	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         	      <Header hidesearch="true" />

				<div className="w-full bg-light">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400"> Questions From Users</p>
						
							<div className="w-full  px-2 py-2 sm:px-0">
							<Tab.Group onChange={(index) => {
						        console.log('Changed selected tab to:', index)
						        setcurrentab(index);
						      }}>
						      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1" >
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  ${currentab == 0 ? ' bg-white shadow ': '' }`}>Private</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 1 ? ' bg-white shadow ': '' }`}>Public</Tab>
						      </Tab.List>
						      <Tab.Panels>
						        <Tab.Panel className="pt-6 pb-6">
						        {transactions && transactions.length ?
										<>
										{transactions && transactions.length && 	
											<InfiniteScroll
											dataLength={transactions.length} //This is important field to render the next data
											next={fetchData}
											hasMore={hasMore}
											loader={
												<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
											}							
											scrollThreshold={0.7}
											>
											<div className="grid  gap-4 divide-y-1">
											{
												transactions.map((item, key) => (
													<>
													<div className="flex flex-row py-2 px-2 border-[1px] border-accent">
															<div className="w-[30%] sm:w-[13%] p-2 rounded-tr-lg mt-0 text-accent flex flex-col justify-start items-start ">
						                              <div><img src={`https://ui-avatars.com/api/?name=${item?.user?.name}&background=53746C&color=FFFFFF`} className="w-10 rounded-full" /></div>
						                              <div className="text-body text-sm ">{date_format(item?.user)}</div>
						                           </div>
															<>
															<div className="text-body font-medium hover:text-secondary-color">
															<Link to={`${APP_URL}/question/${item?.question_slug}`} className="w-full flex ">
																{replaceWithBr(item?.qans_text)}
															</Link>
															</div>
															</>
													</div>
													</>
												))
											}
											</div>
											</InfiniteScroll>	
											}
											</>
											:
											<NoRecords message="Sorry, No Questions  Yet :(" />
										}

						        </Tab.Panel>
						        <Tab.Panel className="pt-6 pb-6">
						         {public_question && public_question.length ?
										<>
										{public_question && public_question.length && 	
											<InfiniteScroll
											dataLength={public_question.length} //This is important field to render the next data
											next={fetchDatapb}
											hasMore={hasMorepb}
											loader={
												<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
											}							
											scrollThreshold={0.7}
											>
											<div className="grid  gap-4 divide-y-1 ">
											{
												public_question.map((item, key) => (
													<>
													<div className="flex flex-row py-2  px-2 border-[1px] border-accent">
															<div className="w-[30%] sm:w-[13%] p-2 rounded-tr-lg mt-0 text-accent flex flex-col justify-start items-start ">
						                              <div><img src={`https://ui-avatars.com/api/?name=${item?.user?.name}&background=53746C&color=FFFFFF`} className="w-10 rounded-full" /></div>
						                              <div className="text-body text-sm ">{date_format(item?.user)}</div>
						                              
						                           </div>
															<><Link to={`${APP_URL}/question/${item?.question_slug}`} className="w-full flex">
															<div className="text-body font-medium hover:text-secondary-color ">
																{replaceWithBr(item?.qans_text)}
															</div>
															</Link>
															</>
													</div>
													</>
												))
											}
											</div>
											</InfiniteScroll>	
											}
											</>
											:
											<NoRecords message="Sorry, No Questions  Yet :(" />
										}

						        </Tab.Panel>
						      </Tab.Panels>
						    </Tab.Group>
						</div>

					
					</div>
				</div>
			      <Footer />


			     
      </ScrollPageTop>
   );
};

export default AllQuestions