
export const Links = {
   HOME:'/',
   LOGOUT:'/logout',
   CONTACT:'/contact',
   ABOUT:'/about',
   HELP:'/help',
   TERMS:'/terms-and-conditions',
   PRIVACY:'/privacy-policy',
   CHANGE_PASSWORD:'/change-password',
   PROFILE:'/profile',
   PROFILE_DOCTOR:'/expert-profile',
   USER_LOGIN:'/login',
   DOCTOR_LOGIN:'/doctor-login',
   DOCTOR_SIGNUP:'/doctor-register',
   BECOMEANEXPERT:'/become-an-expert',
   USER_SIGNUP:'/register',
   RESET_PASSWORD:'/reset-password',
   TESTIMONIALS:'/testimonials',
   EXPERTS:'/experts',
   EXPERT:'/expert',
   CALLNOW:'/expert/callnow',
   ACCEPTCALL:'/expert/accept-call',
   BLOG:'/app',
   ADDMONEYTOWALLET:'/add-money-to-wallet',
   MYWALLET:'/mywallet',
   MYDOCTORWALLET:'/myexpertwallet',
   BANKDETAILLIST:'/bankdetailslist',
   MYCALLSESSION:'/mycallsessions',
   EXPERTCALLSESSION:'/expert/callsessions',
   DOCTORMYRATINGS:'/expert/myratings',
   REGISTRATIONSUCCESS:'/registration-success',
   MYDASHBOARD:'/dashboard',
   DOCTORDASHBOARD:'/expert/dashboard',
   SESSIONSUCCESS:'/session/success',
   EXPERTAVALIBILITY:'/availibilty',
   HOWITWORK:'/how-it-works',
   FAQ:'/faq',
   CHOOSE_ACCOUNT_TYPE:'/account-login',
   TRACk_MENTAL_HEALTH:'/app/track-mental-health/',
   MYQUESTIONS:'/my_questions',
   DISSCUSION:'/questions-from-users',
   QUESTION:'/question',
   ANSWERUSERQUESTION:'/answer-user-questions',
   MYAPPOINTMENTS:'/expert/myappointment-schedule',
   MYAPPOINTMENTSUSER:'/myappointment-schedule'


}