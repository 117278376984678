import React, {useState, useEffect, useRef} from "react";

import { Link , useParams, useNavigate } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";
import ModalView from '_utils/ModalView'

import {
	API_ENDPOINT, 
	BACKEND_URL,
	replaceWithBr, 
	toast_anim, 
	toast_anim_error, 
	APP_URL, 
	APP_NAME,
	IMG_URL, 
	amount_format,
	discount,
	machine_condition,
	showRating,
	amount_with_current
} from 'components/config/Common'
import classNames from 'classnames';
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import { Popover , Transition} from '@headlessui/react'

// import Swiper core and required modules
import { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import AskQuestionForm from 'components/questions/AskQuestionForm';

import {HeartOutlineIcon, HeartFillIcon, ChevronLeft, ChevronRight, Loading} from "components/custom-icons";

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import {useAuth} from 'contexts/provider/AuthProvider'
import Button from 'components/ui/Button.tsx'
import Seo from 'components/seo/seo'
import Badge from "components/ui/badge/badge";
import RelatedDoctors from "./RelatedDoctors";
import CallNowBtn from "components/calling/buttons/CallNowBtn";
import ScheduleAppointmentBtn from "components/appointment/buttons/ScheduleAppointmentBtn";
import * as moment from 'moment';
import ExpertWishlist from 'components/experts/ExpertWishlist';
import ProductRatings from 'components/ui/ProductRatings';
//import AskExpertForm from 'components/doctor/AskExpertForm';
import ContactFrm from 'components/widgets/ContactFrm';
import WhatAppMsgFrm from 'components/widgets/WhatAppMsgFrm';

const DoctorDetails = () => {	
	const navigate = useNavigate();
	const [productsImg, setProductsImg] = useState([])	
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [openrefund , setOpenrefund] = useState(false);
	const [doctor, setDoctor] = useState(null)	

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const {slug} = useParams()
	const { isUser, loginToken } = useAuth();
	
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')
	const [user_type, setUser_type] = useState('')
	const [openwithdraw ,setOpenwithdraw] = useState(false);
	const [opencontactfrm ,setContactFrm] = useState(false);
	const [openwhatsapp ,setWhatsapp] = useState(false);



	const outOfStock = false

	useEffect(() => {
		getDoctorDetail(slug)
		
		setTimeout(() => {
			window.scrollTo({
			  top: 0,
			  left: 0,
			  behavior: "smooth"
			});
		 }, 10);
	},[slug, loginToken])

	useEffect(() => {
		const user_type = localStorage.getItem('user_type');
      setUser_type(user_type);

	},[])

	
	const getDoctorDetail = (slug) => {
		//console.log(user_id)

		fetch(`${API_ENDPOINT}/get_experts_slug_front/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${loginToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('products======',result.data)
			if(result.status === 1){
				setDoctor(result.data)
				setMetaTitle(result.data.name ? result.data.name : result.data.name)
				setMetaDesc(result.data.intro ? result.data.intro : result.data.intro)
			}else{
				//navigate(Links.HOME);
			}
		})
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		//getBankAccountList(1);
	}

	const openWithdrawModal = () =>{
		setOpenwithdraw(true);
	}

	const closeContactFrm = () =>{
		setContactFrm(false);
		//getBankAccountList(1);
	}

	const openContactFrmModal = () =>{
		setContactFrm(true);
	}

	const closeWhatsappFrmModal = () =>{
		setWhatsapp(false);
	}

	const openWhatsappFrmModal = () =>{
		setWhatsapp(true);
	}

	



	 const TagsListsp = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1 "><Badge text={val.label} color="" className="px-1 py-1" /></div>
               ))
               }  
            </>
         );
      }
   }


   const TagsListLan = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1 "><Badge text={val.lan} color="bg-gray-500 " className="px-1 py-1" /></div>
               ))
               }  
            </>
         );
      }
   }


	/* 
	* call this fun from Header.js
	* when user login show wishlist icon
	*/
	const rerenderParentCallback = () => {		
		getDoctorDetail(slug)		
	}

	return (
		<>
		<Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}${Links.PRODUCT}/${slug}`}
         image={productsImg && `${IMG_URL}${productsImg[0]}`}
      />
		<Header rerenderParentCallback={rerenderParentCallback} />
		<section className="pt-10 bg-blueGray-50">
			<div className="w-full lg:w-full px-4 mx-auto">
				<Link to={Links.EXPERTS} className="pb-4"><BiArrowBack size={40} /></Link>
	  		<div className=" flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded-lg ">
	  			 <div className="w-full flex flex-col md:flex-row">
		  			 <div className="w-full md:w-3/5">
		  			 		<div className="flex flex-col md:flex-row">
		  			 		 <div className="w-full md:w-1/4	flex flex-col ml-4 px-2 py-2">
				    		  <div className="h-40 overflow-hidden shadow rounded-full w-40 h-auto align-middle border-none flex-wrap flex">
				          	{doctor?.profile_image ?
				            <img alt="..." src={`${BACKEND_URL}${doctor?.profile_image}`} className="shadow-xl rounded-full h-auto align-middle border-none  max-w-150-px" />
				            :
				            <img src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px" />
				         		}
				          </div>
				          <div className="mt-2 flex items-center justify-start md:justify-center">
                    {doctor?.availability_status == 'online'?
                    (<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Online</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'offline'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Offline</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'busy'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Busy</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'on another call'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">On Another Call</span>
)                                   :null

                    }
                    </div>
                    
                   
										<div className="space-y-6 w-full  mt-2">
                   <Link to={"#_"} onClick={openWithdrawModal} class="cursor-pointer inline-flex w-fit items-center h-8 overflow-hidden text-white bg-accent rounded px-4">
									  <span class="px-5 py-1.5 text-[12px] font-semibold uppercase">
									    Ask An Expert
									  </span>
									  <button 
									    class=" cursor-pointer inline-flex items-center justify-center  transition-color hover:bg-blue-700 focus:outline-none focus:ring"
									    type="button"
									  >

										  </button>
								</Link>
								</div>



 										<div className="space-y-6 w-full  mt-2">
                   <Link to={"#_"} onClick={openWhatsappFrmModal} class="cursor-pointer inline-flex w-fit items-center h-8 overflow-hidden text-white bg-accent rounded px-4">
									  <span class="px-5 py-1.5 text-[12px] font-semibold uppercase">
									     WhatsApp
									  </span>
									  <button 
									    class=" cursor-pointer inline-flex items-center  justify-center  transition-color hover:bg-blue-700 focus:outline-none focus:ring"
									    type="button"
									  >
								<svg  viewBox="0 0 24 24" 
									className="w-4 h-4"
									fill="#fff" 
									xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z" fill="#fff"/>
							</svg>
																	  </button>
								</Link>
								</div>

                   <div className="space-y-6 w-full  mt-2">
                   <Link to={"#_"} onClick={openContactFrmModal} class="cursor-pointer inline-flex w-fit items-center h-8 overflow-hidden text-white bg-accent rounded px-4">
									  <span class="px-5 py-1.5 text-[12px] font-semibold uppercase">
									    Contact Us
									  </span>
									  <button 
									    class=" cursor-pointer inline-flex items-center justify-center  transition-color hover:bg-blue-700 focus:outline-none focus:ring"
									    type="button"
									  >
									 <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"
												 viewBox="0 0 337.559 337.559"  className="w-4 h-4">
											<g>
												<g>
													<g>
														<path d="M337.559,67.704v-28.33c0-17.506-14.242-31.748-31.748-31.748h-54.572c-4.932-3.021-10.727-4.765-16.922-4.765H32.5
															C14.58,2.86,0,17.44,0,35.36v266.838c0,17.921,14.58,32.5,32.5,32.5h201.816c6.196,0,11.992-1.745,16.925-4.767h54.569
															c17.506,0,31.748-14.242,31.748-31.748v-28.33c0-9.715-4.391-18.42-11.287-24.248c6.896-5.828,11.287-14.533,11.287-24.248
															v-28.331c0-9.715-4.391-18.42-11.287-24.248c6.896-5.828,11.287-14.533,11.287-24.248V116.2c0-9.715-4.391-18.42-11.287-24.248
															C333.168,86.123,337.559,77.418,337.559,67.704z M251.816,302.198c0,9.649-7.851,17.5-17.5,17.5H32.5
															c-9.649,0-17.5-7.851-17.5-17.5V35.36c0-9.649,7.851-17.5,17.5-17.5h201.816c9.649,0,17.5,7.851,17.5,17.5V302.198z
															 M322.559,298.184c0,9.235-7.513,16.748-16.748,16.748h-41.595c1.673-3.912,2.601-8.216,2.601-12.733v-49.093h38.994
															c9.235,0,16.748,7.513,16.748,16.748V298.184z M322.559,221.357c0,9.235-7.513,16.748-16.748,16.748h-38.994v-61.827h38.994
															c9.235,0,16.748,7.513,16.748,16.748V221.357z M322.559,144.53c0,9.235-7.513,16.748-16.748,16.748h-38.994V99.451h38.994
															c9.235,0,16.748,7.513,16.748,16.748V144.53z M322.559,67.704c0,9.235-7.513,16.748-16.748,16.748h-38.994V35.36
															c0-4.518-0.929-8.822-2.602-12.735h41.596c9.235,0,16.748,7.513,16.748,16.748V67.704z"/>
														<rect x="40.413" y="230.024" width="185.991" height="15"/>
														<path d="M66.891,206.201h133.035c2.263,0,4.405-1.021,5.829-2.78c1.424-1.759,1.978-4.066,1.507-6.279
															c-3.595-16.907-13.071-32.176-26.474-43.02c8.782-10.818,13.689-24.438,13.689-38.522c0-33.674-27.396-61.07-61.07-61.07
															s-61.07,27.396-61.07,61.07c0,14.084,4.908,27.704,13.689,38.522c-13.402,10.844-22.878,26.112-26.472,43.02
															c-0.471,2.213,0.083,4.521,1.507,6.279C62.486,205.18,64.628,206.201,66.891,206.201z M101.343,161.584
															c1.988-1.245,3.279-3.35,3.488-5.687c0.209-2.337-0.687-4.637-2.422-6.216c-9.579-8.718-15.072-21.14-15.072-34.081
															c0-25.403,20.667-46.07,46.07-46.07c25.403,0,46.07,20.667,46.07,46.07c0,12.941-5.494,25.363-15.072,34.081
															c-1.735,1.579-2.631,3.879-2.422,6.216c0.209,2.337,1.5,4.441,3.488,5.687c11.154,6.989,19.735,17.49,24.42,29.618H76.923
															C81.608,179.074,90.189,168.571,101.343,161.584z"/>
														<rect x="63.83" y="259.688" width="139.156" height="15"/>
													</g>
												</g>
											</g>
											</svg>
									  </button>
								</Link>
								</div>
                    <div className="capitalize text-sm mt-2">{doctor?.short_intro}</div>

				           </div>
			          <div className=" w-full md:w-3/4	flex flex-col ml-4">
			          	 <h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					          {doctor?.name}
					        </h3>
					        <div className="flex">{showRating(doctor?.rating)}
					        {user_type == 'user' && doctor ?
                        <ExpertWishlist item={doctor}  />
                      :
                      <></>
                    }
					        </div>
					          {doctor?.session_price ?
                       <div className="flex  mt-2 text-sm flex-wrap  "><span>Starts @ </span> {amount_with_current(doctor?.session_price)}&nbsp; for 30 mins</div>
                       :
                       null
                    }
					        
					          <p className="mb-2 whitespace-pre-wrap text-md mt-4 leading-relaxed text-blueGray-700">
            					{doctor?.intro}
            				</p>
            				 {doctor?.next_available_date ?
	                    <div className="flex  mt-2 text-sm mb-2  "><span className="font-semibold">Next Available Appointment: </span>&nbsp; {moment(doctor.next_available_date).format('DD MMM , YYYY hh:mm A')} </div>
	                    :
	                    null
	                  }
			           	<div>
				           	{doctor?.availability_status != 'online'?
		                   <ScheduleAppointmentBtn info={doctor} />
		                :
		                    <div className="flex">
		                    	{/*<div className="mt-2"><CallNowBtn  info={doctor} /></div>*/}
                         	<div className="ml-2 mt-2"><ScheduleAppointmentBtn info={doctor}  /></div>
                         </div>
		                }
		                </div>
	                </div>
	              </div>
		          </div>
		          <div className="w-full md:w-2/5	ml-4">
		          	 <div className="mt-4 flex flex-wrap  align-middle"><span className="uppercase text-gray-500">Total Conversations</span>: 4843</div>
		          	 <div className="mt-4 flex flex-wrap  align-middle">{TagsListsp(doctor?.specalizationdt)}</div>
	           		 <div className="mt-4 flex flex-wrap  align-middle">{TagsListLan(doctor?.languagesdt)}</div>
	           		 
		         	</div>
		        </div>
	    	</div>

	    	<div className=" flex flex-col md:flex-row w-full">
		    	<div className="w-full md:w-5/6 mr-8">
		    		<div className="flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Experience
					  </h3>
					  <p>{doctor?.experience}</p>
		    		</div>
		    		<div className="flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Education
					  </h3>
					  <p>{doctor?.qualification}</p>
		    		</div>

		    		<div className="flex divide-y-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Reviews
					  </h3>
					  <p><ProductRatings slug={slug} /></p>
		    		</div>
		    	</div>
		    	<div className="w-full md:w-2/6	flex justify-end">
				    	{doctor?.id > 0 && 
							<RelatedDoctors 
								slug={doctor?.slug} 
								specilization={doctor}	
							/>
						}
		    	</div>
	    	</div>
	    </div>
		</section>
		
		<Footer />
		<ModalView open={openwithdraw} onClose={closeWithdraw}>
				<AskQuestionForm closeWithdraw={closeWithdraw} expert_id={doctor?.id} />
		</ModalView>
		<ModalView open={openwhatsapp} onClose={closeWhatsappFrmModal}>
				<WhatAppMsgFrm closeWithdraw={closeWhatsappFrmModal} />
		</ModalView>
		<ModalView open={opencontactfrm} onClose={closeContactFrm}>
				<ContactFrm closeWithdraw={closeContactFrm} />
		</ModalView>
		</>
		);
	
};

export default DoctorDetails