import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {API_ENDPOINT, toast_anim , pdfdoconly} from 'components/config/Common'
import {Loading} from "components/custom-icons";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

import axios from 'axios';  

import {APP_URL, APP_NAME , IMG_URL} from 'components/config/Common'
import FileInput from 'components/ui/file-input.jsx';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import SelectInput from 'components/ui/select-input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import { Switch } from '@headlessui/react'


import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

/*	email: Yup.string()
		.required('You must need to provide email address.')
		.email('Invalid email.'),		*/
const SignupSchema = Yup.object().shape({
	name: Yup.string()
		.required('You must need to provide name.')
		.min(2, 'Name is too short!')
		.max(50, 'Name is too long!'),
});


let defaultValues = {  
	name: '',
	email:'',
	phone_no:'',
	password:'',
	gender:'',
};

const Profile = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
     const [enabled, setEnabled] = useState(false)

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		
  	});

  		const refphone = useRef(null);

	useEffect(() => {
		getMyProfile()
		
	},[])
	

  const handleSelect = (val,name) => {  
		console.log('cat val======',val,name.name)
		setError(name.name, null)
		setValue(name.name, val, true);
		
	}


	const getMyProfile = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/user_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
			setUser_me(result.data)
			let data = result.data
			defaultValues = {  
				name: data.name,
				email:data.email,
				gender:data.gender,
				phone_no:data.phone_no,
				user_name:data.user_name,
			};
			reset(defaultValues)
			
		})
	}	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Profile - ${APP_NAME}`,
		description: `Profile ${APP_NAME}`,
	}

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
     
      setLoading(true)
      axios.post(`${API_ENDPOINT}/update_user_profile`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         /*console.log('data=====',result.data)
         console.log('IN UPDATE',result?.data?.data);
         setLoading(false)
         
         */
         console.log('data=====',result?.data?.data?.name);
			if(result.data.status === 1){
				setLoading(false)
				localStorage.setItem('user_name',result?.data?.data?.name);
				toast_anim(result.data.message)
			}
      })
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-xl lg:text-2xl font-semibold text-heading capitalize mb-5 dark:text-zinc-400">My Profile</p>
						{
						user_me &&	
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex w-full items-center mb-5">
								<span className="block text-sm font-semibold leading-none text-body-dark">Your anonymous Id</span> &nbsp; {defaultValues?.user_name}
							</div>
							<div className="flex w-full">
								<Input                
	                            label={`Name`}
	                            {...register('name')}
	                            variant="outline"
	                            className="mb-5 w-1/2"
	                            error={errors.name?.message}
	                            required
	                        />
	                        
	                        <div className="flex w-1/2 ml-2 items-center justify-start">
						      	<em className="text-sm">Only visible to you</em>
						    		</div>
	                        </div>

	                        <div className="flex w-full align-center">
								<Input                
		                            label={`Email`}
		                            {...register('email')}
		                            variant="outline"
		                            className="mb-5  w-1/2"
		                            type="email"
		                            error={errors.email?.message}
		                             disabled={true}
		                        />
		                       <div className="flex w-1/2">
							    </div>
							</div>
								<div className="mb-5 w-1/2 flex flex-col">
		                     <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number </label>
		                     <Controller
		                        name="phone_no"
		                        control={control}
		                        ref={refphone}

		                        render={({ field }) => (
		                           <PhoneInput
		                              country="in"
		                              disableDropdown
		                              countryCodeEditable={false}
		                              inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
		                              dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
		                              {...field}
		                              disabled={true}
		                           />
		                        )}
		                     />
		                     <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
		                  </div>
		                  <div className="mb-5 w-1/2">
			                  <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Gender<span class="text-red-500"> *</span></label>
			                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-license"   {...register('gender')}  type="radio" value="male" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"  />
			                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Male </label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-id" type="radio" {...register('gender')}  value="female" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Female</label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-millitary" type="radio" {...register('gender')}  value="non-binary" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Non Binary</label>
			                          </div>
			                      </li>
			                  </ul>
			                  </div>
							
								<div className="flex">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto uppercase">Save</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}
								</div>
							</form>
						}
					</div>
					
					

					
					
				</div>
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default Profile