import React, {useState, useEffect , useRef} from "react";
import Header from 'layouts/Header'
import Footer from 'layouts/Footer'
import Logo from 'layouts/logo';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {EyeOff, Eye, Loading} from "components/custom-icons";
import Button from 'components/ui/Button.tsx'
import Input from 'components/ui/input.jsx'

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';

import axios from 'axios';  
import { yupResolver } from '@hookform/resolvers/yup';
import {API_ENDPOINT,toast_anim , toast_anim_error} from 'components/config/Common'
import PasswordInput from 'components/ui/password-input.tsx'

import {useAuth} from 'contexts/provider/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {Links} from 'routes/PathLink'
import ModalView from '_utils/ModalView'
import { produce } from 'immer';
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import SocialLogin from "components/auth/SocialLogin";

const SignupSchema = Yup.object().shape({
	 email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
   password: Yup.string().required('Password is required')
});

const defaultValues = {  
   password: '',
 };

const LoginwithEmail = (props) => {
   const [show, setshow] = useState(false);
   const [loading, setLoading] = useState(false);
   const {form,setForm} = useDoctorReg();

   const { login } = useAuth();
   let navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [otp, setotp] = useState('');
  const [final, setfinal] = useState('');
  const [whereto, setWhereto] = useState('');
  const [emailvar, setEmailVar] = useState(false);
  const [userData,setUserData] = useState([]);
 const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
   });
const refphone = useRef(null);
   useEffect(() => {
     
      if(localStorage.getItem('access_token')){
         return navigate("/", { replace: true });
      }
   })

   const handleView = () => {
		setIsOpen(true)
	}

    
   const closeModal = () => {
      setIsOpen(false)      
   }

    const loginsubmit = async (values) => {
       //props.setActivestepfun(2);
       setLoading(true);
       console.log("Value from form ",values)
         /*setForm(
          produce((formState) => {
            formState.steps.main = {
              values,
              valid: true,
              dirty: false,
            };
          }));
          */
          console.log("form Data ",form);
       
          setEmailVar(values?.email);
          axios
         .post(API_ENDPOINT+'/check_user_exist', values)
         .then((response) => {
            console.log("RESPONSE",response.data);

            if(response.data?.status == 1){
                  let input ={
                  email:values?.email,
                  password:values?.password,
                } ;
                  //input = {...input, device:deviceDetect()}

                  fetch(`${API_ENDPOINT}/user_login`,{
                     method: 'POST',
                     headers : {        
                        'Content-Type': 'application/json',         
                     },
                     body: JSON.stringify(input)
                  }).then(function (response) {
                        return response.json();
                  }).then( (result)=> {
                     //console.log(result)
                     setLoading(false)
                     if(result.status === 1){
                        localStorage.setItem('access_token',result.access_token);
                        localStorage.setItem('user_type',result.user_type);
                        localStorage.setItem('user_id',result.user_id);
                        localStorage.setItem('user_name',result.name);
                        //props.setLogin()
                        login({access_token:result.access_token, user_name:result.user_name})
                        return navigate(Links.MYDASHBOARD, { replace: true });
                     }
                     if(result.status === 0){
                         toast_anim_error(result?.message);
                        //setErrors({password:result.message})
                     }
                     //setErrors({phone_no:result.message})
                  })
            }else{
               setLoading(false);
                toast_anim_error("Your are not registered with us yet , please Sign up.");
            }
         });
       return false;
   }

   
   return( <div className="flex justify-center">
            <div className="flex h-full  w-screen flex-col justify-center bg-light  px-5 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
                 <>   
                  <form onSubmit={handleSubmit(loginsubmit)}>  
                     <div className="mb-5">
                          <Input                
                              label={`Email`}
                              {...register('email')}
                              variant="outline"
                              className="mb-5"
                              type="email"
                              error={errors.email?.message}
                              required
                           />
                     </div>
                     <div className="mb-2 w-full flex flex-col">
                     <PasswordInput                
                        label={`Password`}
                        {...register('password')}
                        variant="outline"
                        className="mb-5"                        
                        type='password'
                        error={errors.password?.message}
                        required
                     />
                     </div>
                     <div className="mt-8 flex justify-center">
                        {
                        !loading
                        ?
                        <Button className="w-full uppercase" >Login</Button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>
               </form>
               </>
            </div>
         </div>)
}

export default LoginwithEmail