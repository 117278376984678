
import {APP_URL, IMG_URL, amount_format,discount} from 'components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";

const supportData = [
  {
    img:'support/sad.png',
    title: 'Depression',
    desc:'Are you feeling stuck in your sadness, struggling to see a way forward? We\'re here to help.',
  },
  {
    img:'support/emotion.png',
    title: 'Social Anxiety',
    desc:'Do social situations make you feel anxious and nervous? We\'re here to help you handle them with more confidence.',
  },
  
  {
    img:'support/cigarette.png',
    title: 'Alcohol Addiction',
    desc:'Is alcohol getting in the way of living your life to the fullest? The right support is available for you.',
  },
  {
    img:'support/bipolar.png',
    title: 'Bipolar Disorder',
    desc:'Do you go through extreme highs and lows, feeling really happy one moment and deeply sad the next? We\'re here to provide the care you need.',
  },
  {
    img:'support/fear.png',
    title: 'Generalised Anxiety Disorder (GAD)',
    desc:'Do you often feel anxious, restless, or uneasy? We\'re here to help you find better ways to cope.',
  },
  {
    img:'support/ocd.png',
    title: 'Obsessive Compulsive Disorder (OCD)',
    desc:'Are unwanted thoughts causing anxiety and leading to unhelpful habits? There are ways to cope, and we can help.',
  },  
  
]




const HowItWork = () => {

   return(
      <div className="">
       <div className="grid grid-cols-1 md:grid-cols-3 gap-10 ml-10 mr-10">
              <div className=" flex flex-col rounded-2xl shadow-[6px_4px_6px_4px_rgba(0,_0,_0,_0.1)]">
                <div className="flex flex-col items-center justify-center py-3">
                  <div className="flex justify-center text-3xl w-10 h-10  rounded-full split-number">01</div>
                  <div className="ml-2 text-xl font-medium">Find Online Experts</div>
                </div>
                <div className="mt-2 text-center">Take your pick from our panel of mental health & self-improvement experts</div>
                <img src={`${APP_URL}/img/findexpert.jpg`} />
              </div>
              <div className="flex flex-col rounded-2xl mt-10 shadow-[6px_4px_6px_4px_rgba(0,_0,_0,_0.1)]">
                <div className="flex flex-col items-center justify-center py-3">
                  <div className="flex justify-center text-3xl w-10 h-10  split-number rounded-full">02</div>
                  <div className="ml-2 text-xl font-medium">Call Expert</div>
                </div>
                <div className="mt-2 text-center">Initiate an instant chat or call with the expert for a price as low as ₹3/min</div>
                <img src={`${APP_URL}/img/callexpert.jpg`} className="rounded-2xl" />
              </div>
              <div className="flex flex-col rounded-2xl mt-20 shadow-[6px_4px_6px_4px_rgba(0,_0,_0,_0.1)]">
                <div className="flex flex-col items-center justify-center py-3">
                  <div className="flex justify-center text-3xl text-white w-10 h-10  split-number rounded-full">03</div>
                  <div className="ml-2 text-xl font-medium">Unleash a Better You</div>
                </div>
                <div className="mt-2 text-center">Share your thoughts, get support, and feel better</div>
                <img src={`${APP_URL}/img/discusswithexpert.jpg`} className="rounded-2xl"  />

              </div>
            </div>
    </div>
   )
}



export default HowItWork;