import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT , amount_format} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import WithdrawMoneyPatient from "components/wallet/WithdrawMoneyPatient";
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords';
import StickerCard from "components/widgets/sticker-card";

import UserUpcomingAppointment from "components/appointment/UserUpcomingAppointment";
import UserTodaysAppointment from "components/appointment/UserTodaysAppointment";
import UserPendingAppointment from "components/appointment/UserPendingAppointment";
import { Tab } from '@headlessui/react'


const MyDashboard = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);

      const [currentab ,setcurrentab] = useState(0);


	useEffect(() => {
		getWalletTotal();
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My wallet - ${APP_NAME}`,
		description: `My wallet - ${APP_NAME}`,
	}

	const getWalletTotal = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/total_wallet_amount`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log("TOTAL WALLET AMOUNT ",result)
			if(result.status == 1){
				setTotalamount(result.data);
			}
		})
	}	


	
	

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full ">
					<div className={classNames('w-full p-5 md:p-8  shadow rounded mb-8')}>
						{/*<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Welcome You are logged In.</p>*/}
						
						<div className="flex mb-5 w-full justify-between flex-col md:flex-row ">
						<div className="w-full md:w-3/12 mb-5 flex flex-col">
							
							<Link to={Links.MYWALLET}>
					            <StickerCard
					            titleTransKey="Wallet Amount"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white " size={40} />}
					            linkText={amount_format(totalamount)}
					            link={Links.MYWALLET}
					          />
				          </Link>
						</div>
						<div className="w-full md:w-3/12 mb-5 flex flex-col ml-2">
							
							<Link to={'#'}>
					            <StickerCard
					            titleTransKey="Total Session"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white " size={40} />}
					            linkText={'10'}
					            link={'#'}
					          />
				          </Link>
						</div>
						<div className="w-full md:w-3/12 mb-5 flex flex-col ml-2">
							
							<Link to={'#'}>
					            <StickerCard
					            titleTransKey="Pending Appointment"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white " size={40} />}
					            linkText={0}
					            link={'#'}
					          />
				          </Link>
						</div>
						<div className="w-full md:w-3/12 mb-5 flex flex-col ml-2">
							<Link to={'#'}>
					            <StickerCard
					            titleTransKey="Upcoming Appointment"
					            icon={<WalletPointsIcon className="w-5 mr-2 text-3xl text-white " size={40} />}
					            linkText={0}
					            link={'#'}
					          />
				          </Link>
						</div>
						</div>
						<div className="w-full  px-2 py-2 sm:px-0">
							<Tab.Group onChange={(index) => {
						        console.log('Changed selected tab to:', index)
						        setcurrentab(index);
						      }}>
						      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1" >
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  ${currentab == 0 ? ' bg-white shadow ': '' }`}>Today's Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 1 ? ' bg-white shadow ': '' }`}>Upcoming Appointment</Tab>
						        <Tab className={`w-full rounded-lg uppercase py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 2 ? ' bg-white shadow ': '' }`}>Pending Appointment</Tab>
						      </Tab.List>
						      <Tab.Panels>
						        <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"><UserTodaysAppointment /></Tab.Panel>
						        <Tab.Panel><UserUpcomingAppointment /></Tab.Panel>
						        <Tab.Panel><UserPendingAppointment /></Tab.Panel>
						      </Tab.Panels>
						    </Tab.Group>
						</div>
						{/*<div className="flex mb-5 w-full flex-col">
						<div className="flex mb-5 w-full">
							<div className="w-full  align-end flex">
							<UserTodaysAppointment />
							</div>
						</div>
						<div className="flex mb-5 w-full ">
							<div className="w-1/2  mb-5 flex">
							<UserUpcomingAppointment />
							</div>
							<div className="w-1/2  mb-5 flex ml-2">
							<UserPendingAppointment />
							</div>
						</div>*/}
							{/* <div className="w-1/2 align-end flex">
							 <Button onClick={openWithdrawModal}>WithDraw Money</Button>
							</div> */}
						{/* </div> */}
					</div>
				</div>
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default MyDashboard