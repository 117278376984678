import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import * as yup from 'yup';

import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from 'components/config/Common'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import Seo from 'components/seo/seo'
import Input from 'components/ui/input.jsx'
import TextArea from 'components/ui/text-area.jsx'
import Button from 'components/ui/Button.tsx'


import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = yup.object().shape({
   message: yup.string().required('Message is required'), 
})
let defaultValues = {  
   message:''
};

const WhatAppMsgFrm = (props) => {

   const [loading, setLoading] = useState(false)
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });


   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/contact`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
        // setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   const onSubmit = (values) => {
      //console.log('contact', values)
      let number =  +918208636201;
      // Appending the phone number to the URL
       let url = `https://web.whatsapp.com/send?phone=${number}`;

       // Appending the message to the URL by encoding it
       url += `&text=${encodeURI(values?.message)}&app_absent=0`;

       // Open our newly created URL in a new tab to send the message
       window.open(url);
   }

   return(
      <ScrollPageTop>
      <div className="w-full bg-gray-100 ">
         <div className="flex w-full  flex-col  md:flex-row  ">
           
            <div className="order-1 mb-8  w-full bg-light p-5 md:order-2 md:mb-0 md:p-4">
               
               <h1 className="mb-4 font-body text-xl font-bold text-heading md:text-2xl">
               Send WhatsApp Message
               </h1>
               <form onSubmit={handleSubmit(onSubmit)} className="w-full  ">
                  <div className="grid grid-cols-1">
                     
                  <TextArea
                     label='Message'
                     {...register('message')}                        
                     variant="outline"
                     className="mb-5 w-full"
                     error={errors.message?.message}
                     required
                  />
               </div>
                  <Button loading={loading}>
                     Send
                  </Button>

               </form>
            </div>
         </div>
      </div>
      </ScrollPageTop>
   )
}

export default WhatAppMsgFrm;